
// Button
// =============================================================================

.btn {
  display: inline-block;
  border: 0;
  border-radius: 35px;
  padding: 18px 25px;
  box-shadow: none !important;
  font-family: $font-family-sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.3em;
  white-space: normal;
  text-decoration: none !important;
  text-transform: uppercase;
  @include transition(all .2s);
  &.btn-primary, &.btn-default, &.btn1 {
    background-color: $brand-primary;
    color: #fff;
    &:hover {
      background-color: darken($brand-primary, 15%);
    }
  }
  &.btn-secondary {
    background-color: transparentize($brand-primary, .95);
    color: $brand-primary;
    &:hover {
      background-color: $brand-primary;
      color: #fff;
    }
  }
  &.btn-white {
    background-color: #fff;
    color: $brand-primary;
    &:hover {
      background-color: $brand-primary;
      color: #fff;
    }
  }
  &.btn-link, &.btn2 {
    position: relative;
    margin-right: 25px;
    background-color: transparent !important;
    border-bottom: 2px solid #dce0ed;
    border-radius: 0;
    padding: 10px 0;
    color: $brand-secondary;
    font-size: 0.75rem;
    letter-spacing: 1.5px;
    .fa {
      position: absolute;
      left: 100%;
      margin-left: 10px;
      font-size: 1rem;
    }
    &:hover {
      border-color: transparent;
    }
  }
}

input[type="submit"] {
  @extend .btn;
  @extend .btn-primary;
  cursor: pointer;
}
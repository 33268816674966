// -------------------------------------------------------------
//   Banner
// -------------------------------------------------------------

#banner {
	position: relative;
	z-index: 0;
	height: 450px;
	background-color: #000;
	&:before, &:after {
		content: '';
		position: absolute;
	}
	&:before {
		z-index: 1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: -moz-linear-gradient(49deg,  rgba(38,60,83,1) 0%, rgba(38,60,83,0.74) 26%, rgba(38,60,83,0) 100%);
		background: -webkit-linear-gradient(49deg,  rgba(38,60,83,1) 0%,rgba(38,60,83,0.74) 26%,rgba(38,60,83,0) 100%);
		background: linear-gradient(49deg,  rgba(38,60,83,1) 0%,rgba(38,60,83,0.74) 26%,rgba(38,60,83,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#263c53', endColorstr='#00263c53',GradientType=1 );
		opacity: .58;
	}
	&:after {
		z-index: 2;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
		background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
		opacity: .4;
	}
	.content {
		position: absolute;
		z-index: 5;
		bottom: 155px;
		left: 0;
		width: 100%;
		text-align: center;
	}
	.bg {
		opacity: .4;
	}
}
// -------------------------------------------------------------
//   Homepage
//   Homepage specific styles
// -------------------------------------------------------------

#leaderboard {
	position: relative;
}

.swiper-container {
	width: 100%;
    height: 750px;

    @include media-breakpoint-down(sm) {
		height: 500px;
    }
    .swiper-slide {
    	background-color: #000;
    	.container.md {
    		box-sizing: border-box;
    	}
    	&:before, &:after {
    		content: '';
    		position: absolute;
    	}
    	&:before {
    		z-index: 1;
    		top: 0;
    		right: 0;
    		bottom: 0;
    		left: 0;
    		background: -moz-linear-gradient(49deg,  rgba(11,52,208,1) 0%, rgba(11,52,208,0.74) 26%, rgba(11,52,208,0) 100%);
			background: -webkit-linear-gradient(49deg,  rgba(11,52,208,1) 0%,rgba(11,52,208,0.74) 26%,rgba(11,52,208,0) 100%);
			background: linear-gradient(49deg,  rgba(11,52,208,1) 0%,rgba(11,52,208,0.74) 26%,rgba(11,52,208,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0b34d0', endColorstr='#000b34d0',GradientType=1 );
			opacity: .58;
    	}
    	&:after {
    		z-index: 2;
    		bottom: 0;
    		left: 0;
    		width: 100%;
    		height: 400px;
    		background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
			background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
    		opacity: .4;
    	}
    	.content {
    		position: absolute;
    		z-index: 5;
    		top: 50%;
    		width: 100%;
    		text-align: center;
    		-ms-transform: translate(0,-50%);
		    -webkit-transform: translate(0,-50%);
		    transform: translate(0,-50%);
    		h1, h2 {
    			margin: 0 0 60px;
    			color: #fff;
    			font-size: 1.75rem;
    			font-weight: 400;
    			line-height: 1.7em;

    			@include media-breakpoint-down(sm) {
    				margin-bottom: 15px;
					font-size: 1.15rem;
					line-height: 1.5em;
    			}
    		}
    		.btn {
    			width: 320px;
    			max-width: 100%;
    		}
    	}
    	.bg {
    		opacity: .9;
    	}
    }
    .swiper-button-next, .swiper-button-prev {
    	margin-top: -18px;
    	width: 19px;
    	height: 36px;
    	background-size: 19px 36px;
    	opacity: .65;
    	@include transition(opacity .2s);

    	@include media-breakpoint-down(lg) {
			top: auto;
			bottom: 210px;
    	}

    	@include media-breakpoint-down(sm) {
			bottom: 60px;
    	}
    	&:hover {
    		opacity: 1;
    	}
    	&.swiper-button-disabled {
    		opacity: 0;
    	}
    }
    .swiper-button-next {
    	right: 60px;
    	background-image: url(../images/icon-next.png);
    }
    .swiper-button-prev {
    	left: 60px;
    	background-image: url(../images/icon-prev.png);

    	@include media-breakpoint-down(lg) {
    		right: 120px;
			left: auto;
    	}
    }
}

.swiper-pager {
	position: absolute;
	z-index: 5;
	bottom: 0;
	left: 0;
	width: 100%;
	font-size: 0;
	text-align: center;

	@include media-breakpoint-down(sm) {
		position: relative;
		bottom: auto;
		left: auto;
	}

	@include media-breakpoint-down(xs) {
		.container {
			padding: 0;
		}
	}
	.wrapper {
		background-color: #fff;
	}
	a {
		display: inline-block;
		vertical-align: middle;
		padding: 30px 20px 25px;
		width: 25%;
		height: 180px;
		color: #424242;
		font-size: 0.75rem;
		font-weight: 500;
		letter-spacing: 1.5px;
		line-height: normal;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		@include transition(background-color .2s);

		@include media-breakpoint-down(sm) {
			width: 50%;
		}

		@include media-breakpoint-down(xs) {
			width: 100%;
		}
		svg {
			display: inline-block;
			fill: $brand-blue;
			@include transition(fill .2s);
		}
		span {
			display: block;
			margin-top: 30px;
			@include transition(all .2s);
		}
		&:hover, &.active {
			background-color: transparentize(#0078ad, .95);
			svg {
				fill: $brand-primary;
			}
			span {
				color: $brand-primary;
				-ms-transform: translate(0,-15px);
			    -webkit-transform: translate(0,-15px);
			    transform: translate(0,-15px);
			}
		}
	}
}

#intro {
	padding-top: 75px;
	padding-bottom: 75px;
	background-color: #fff;
	color: #ababab;
	font-size: 1.4rem;
	font-weight: 300;
	line-height: 1.65em;
	text-align: center;
	p {
		margin: 0 0 35px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	strong {
		color: #636363;
		font-weight: 500;
	}
	.phone {
		width: 240px;
		max-width: 100%;
		.fa {
			margin-right: 5px;
		}
	}
	.wrapper {
		margin-left: auto;
		margin-right: auto;
		max-width: 860px;
	}
}

#txt {
	position: relative;
	background-color: #eef2ff;
	overflow: hidden;

	@include media-breakpoint-down(xs) {
		.container {
			padding: 0;
		}
	}
	.content {
		position: relative;
		z-index: 5;
	}
	/* .bg {
		-webkit-filter: blur(8px);
		filter: blur(8px);
	} */
	.txt-bloc {
		p {
			margin: 0 0 40px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.txt-content, .cta_left {
		padding: 45px 65px;
		color: #555e7d;

		@include media-breakpoint-down(lg) {
			padding-left: 45px;
			padding-right: 45px;
		}

		@include media-breakpoint-down(md) {
			padding-left: 30px;
			padding-right: 30px;
		}
		> * {
			max-width: 440px;
		}
	}
	.txt-content {
		padding-top: 70px;
	}
	.cta_left {
		background-color: #fff;
		.btn2 {
			padding: 5px 0;
			border-color: $brand-secondary;
			font-size: 0.9rem;
			letter-spacing: 0.5px;
			&:after {
				content: '\f061';
				position: absolute;
				left: 100%;
				margin-left: 10px;
				font-family: 'FontAwesome';
				font-size: 1rem;
			}
			&:hover {
				border-color: transparent;
			}
		}
	}
	.cta_right {
		padding: 60px 15px;
		background-color: transparentize($brand-primary, .1);
		color: #fff;
		font-size: 1.5rem;
		line-height: 1.35em;
		text-align: center;
		text-decoration: none;
		@include transition(all .2s);
		svg {
			display: inline-block;
			width: 85px;
			height: 80px;
			fill: #fff;
		}
		p {
			margin: 15px 0 0;
		}
		span {
			display: block;
			font-size: 0.75rem;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		.fa {
			display: block;
			margin-top: 30px;
			font-size: 1.25rem;
			line-height: 1em;
		}
		&:hover {
			background-color: $brand-primary;
		}
	}
}

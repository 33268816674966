// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

.footer-top {
	position: relative;
	padding-top: 180px;
	padding-bottom: 180px;
	background-color: $brand-secondary;
	color: #fff;
	line-height: 1.5em;

	@include media-breakpoint-down(sm) {
		padding-top: 60px;
		padding-bottom: 0;
	}
	&:after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 50%;
		background: -moz-linear-gradient(left,  rgba(38,60,83,0) 0%, rgba(38,60,83,1) 15%);
		background: -webkit-linear-gradient(left,  rgba(38,60,83,0) 0%,rgba(38,60,83,1) 15%);
		background: linear-gradient(to right,  rgba(38,60,83,0) 0%,rgba(38,60,83,1) 15%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00263c53', endColorstr='#263c53',GradientType=1 );

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	h2 {
		margin: 0 0 20px;
		color: #fff;
		font-weight: 300;
		line-height: 1.35em;
	}
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		color: #fff;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.btn {
		margin-top: 55px;
		padding-left: 30px;
		padding-right: 30px;
		background-color: transparentize(#eef2ff, .9) !important;
		.fa {
			margin-left: 10px;
		}
		&:hover {
			background-color: $brand-primary !important;
		}
	}
	.content {
		position: relative;
		z-index: 5;

		@include media-breakpoint-down(sm) {
			margin-bottom: 60px;
		}
		.wrapper {
			padding-left: 30px;
		}
	}
	.bg {
		right: 40%;
		@include media-breakpoint-down(sm) {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			padding-bottom: 56.25%;
		}
	}
}

.copyright {
	padding: 35px 0;
	color: #9a9a9a;
	font-size: 0.9rem;
	text-align: center;
	p {
		margin: 0;
	}
	a {
		color: #9a9a9a;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}
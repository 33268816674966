// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

.main-header {
  position: absolute;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  @include transition(background-color 0.2s);

  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 0 !important;
  }
  .logo {
    position: absolute;
    top: 20px;
    left: 50%;
    max-height: 130px;
    overflow: hidden;
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    @include transition(all 0.2s);
    img {
      @include transition(all 0.2s);
    }
  }
  .home {
    display: block;
    position: relative;
    margin-top: 30px;
    padding: 21px 15px;
    color: #fff;
    font-size: 0.95rem;
    font-weight: 500;
    line-height: 1.2em;
    text-decoration: none;
    @include transition(padding 0.2s);

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: transparentize(#fff, 0.85);
    }
  }
}

.top-bar {
  @include clearfix;
  background-color: $brand-secondary;
  .infos,
  .links {
    > * {
      float: left;
    }
  }
  .infos {
    float: left;

    @include media-breakpoint-down(xs) {
      position: relative;
      z-index: 1;
    }
    > * {
      display: block;
      padding: 21px 0;
      color: #fff;
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 1.3em;
      text-decoration: none;
      .fa {
        margin-right: 10px;
        color: $brand-primary;
        font-size: 1rem;
        font-weight: 400;
      }
      @include media-breakpoint-down(md) {
        position: relative;
        padding-left: 24px;
        padding-right: 24px;
        background-color: $brand-primary;
        color: #fff;
        &.facebook {
          padding-left: 26px;
          padding-right: 26px;
        }
        .fa {
          margin: 0;
          color: #fff;
        }
        span {
          position: absolute;
          top: 100%;
          left: 0;
          padding: 0 15px;
          height: 0;
          background-color: $brand-primary;
          overflow: hidden;
          white-space: nowrap;
          @include transition(all 0.2s);
          &.open {
            padding-top: 10px;
            padding-bottom: 10px;
            height: 38px;
          }
        }
      }
      & + * {
        margin-left: 40px;
        @include media-breakpoint-down(md) {
          margin-left: 1px;
        }
      }
    }
    > a {
      @include transition(all 0.2s);
      &:hover {
        color: darken(#fff, 15%);
      }
    }
  }
  .links {
    float: right;
    > * {
      & + * {
        margin-left: 25px;

        @include media-breakpoint-down(sm) {
          margin-left: 15px;
        }
      }
    }
    .social {
      display: block;
      padding: 21px 0 20px;
      color: $brand-primary;
      font-size: 0.9rem;
      letter-spacing: 0.5px;
      line-height: 1.3em;
      text-decoration: none;
      @include transition(all 0.2s);

      @include media-breakpoint-down(md) {
        padding-left: 25px;
        padding-right: 25px;
        span {
          display: none;
        }
      }
      &:hover {
        color: darken($brand-primary, 15%);
      }
      .fa {
        margin-left: 15px;
        font-size: 1.15rem;

        @include media-breakpoint-down(md) {
          margin-left: 0;
        }
      }
    }
  }
}

.medecins {
  .btn {
    margin: 13px 0;
    padding: 8px 25px;
    letter-spacing: 0;
    font-weight: 500;
    text-transform: none;

    @include media-breakpoint-down(lg) {
      background-color: #fff !important;
      color: $brand-primary !important;
      &:hover {
        background-color: darken(#fff, 15%) !important;
      }
    }
    .fa {
      margin-left: 5px;
      font-weight: 400;
    }
  }
}

.main-header.scrolled {
  position: fixed;
  top: -60px;
  background-color: $brand-primary;
  .logo {
    top: 10px;
    max-height: 75px;
    @include media-breakpoint-down(lg) {
      top: -50px;
      max-height: 40px;
      img {
        width: 128px;
      }
    }
  }
}

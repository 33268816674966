// -------------------------------------------------------------
//   Webform
// -------------------------------------------------------------

.node-webform {
	padding: 30px 60px;

	@include media-breakpoint-down(lg) {
		padding-left: 30px;
		padding-right: 30px;
	}

	@include media-breakpoint-down(sm) {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.page-node-done {
	#main {
		.main-wrapper {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

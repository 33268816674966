// -------------------------------------------------------------
//   Paragraphs item galerie
// -------------------------------------------------------------

.content-galerie {
	padding: 30px 0;
	&::after {
		display: block;
		content: "";
		clear: both;
	}
	.owl-nav {
		float: left;
		margin: 0;
		[class*="owl-"] {
			margin: 0;
			padding: 5px 10px;
			background-color: transparent;
			border-radius: 0;
			color: $brand-primary;
			font-size: 1.5rem;
			line-height: 1em;
			&:hover {
				background-color: $brand-primary;
			}
		}
	}
	.owl-dots {
		float: right;
		.owl-dot { 
			span {
				margin: 10px 5px;
				background-color: #d6d6d6;
			}
			&.active, &:hover {
				span {
					background-color: $brand-primary;
				}
			}
		}
	}
}
// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	margin-top: 0;
	margin-bottom: 40px;
	&:last-child {
		margin-bottom: 0;
	}
}
h1,.h1 {
	margin-bottom: 20px; 
	color: #fff;
	font-weight: 400;
	line-height: 1.1em;
}
p {
	margin: 0 0 30px;
	&:last-child {
		margin-bottom: 0;
	}
}
// -------------------------------------------------------------
//   User
// -------------------------------------------------------------

.page-user {
	#main {
		.main-wrapper {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}
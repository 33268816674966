/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import 
	'custom',
	'../../node_modules/bootstrap/scss/variables',
	'../../node_modules/bootstrap/scss/mixins';

// Reset and dependencies
@import 
	'../../node_modules/bootstrap/scss/normalize',
	'../../node_modules/bootstrap/scss/print';

// Core CSS
@import
	'../../node_modules/bootstrap/scss/reboot',
	'../../node_modules/bootstrap/scss/type',
	'../../node_modules/bootstrap/scss/images',
	'../../node_modules/bootstrap/scss/code',
	'../../node_modules/bootstrap/scss/grid',
	'../../node_modules/bootstrap/scss/tables',
	'../../node_modules/bootstrap/scss/forms',
	'../../node_modules/bootstrap/scss/buttons';

// Components
@import
// 	'../../node_modules/bootstrap/scss/transitions',
// 	'../../node_modules/bootstrap/scss/dropdown',
// 	'../../node_modules/bootstrap/scss/button-group',
// 	'../../node_modules/bootstrap/scss/input-group',
// 	'../../node_modules/bootstrap/scss/custom-forms',
// 	'../../node_modules/bootstrap/scss/nav',
// 	'../../node_modules/bootstrap/scss/navbar',
// 	'../../node_modules/bootstrap/scss/card',
// 	'../../node_modules/bootstrap/scss/breadcrumb',
// 	'../../node_modules/bootstrap/scss/pagination',
// 	'../../node_modules/bootstrap/scss/badge',
// 	'../../node_modules/bootstrap/scss/jumbotron',
// 	'../../node_modules/bootstrap/scss/alert',
// 	'../../node_modules/bootstrap/scss/progress',
// 	'../../node_modules/bootstrap/scss/media',
// 	'../../node_modules/bootstrap/scss/list-group',
 	'../../node_modules/bootstrap/scss/responsive-embed';
// 	'../../node_modules/bootstrap/scss/close';

// Components w/ JavaScript
// @import 
// 	'../../node_modules/bootstrap/scss/modal',
// 	'../../node_modules/bootstrap/scss/tooltip',
// 	'../../node_modules/bootstrap/scss/popover',
// 	'../../node_modules/bootstrap/scss/carousel';

// Utility classes
@import
	'../../node_modules/bootstrap/scss/utilities';

// -------------------------------------------------------------
//   Paragraphs item evidence
// -------------------------------------------------------------

.content-evidence {
	padding: 30px 0;
	&::after {
		display: block;
		content: "";
		clear: both;
	}
	.img-wrapper, .content {
		float: left;
		width: 50%;
		@include media-breakpoint-down(sm) {
			float: none;
			width: 100%;
		}
	}
	.content {
		padding: 30px;
		@include media-breakpoint-down(sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	img {
		width: 360px;
		@include media-breakpoint-down(xs) {
			margin-bottom: 30px;
		}
	}
	p {
		max-width: 430px;
		color: #959595;
		font-size: 1.5rem;
		line-height: normal;
	}
}
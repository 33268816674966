// -------------------------------------------------------------
//   Paragraphs item texte
// -------------------------------------------------------------

.content-texte {
	padding: 30px 60px;

	@include media-breakpoint-down(lg) {
		padding-left: 30px;
		padding-right: 30px;
	}

	@include media-breakpoint-down(sm) {
		padding-left: 15px;
		padding-right: 15px;
	}
	.row {
		margin-bottom: 70px;
		&:last-child {
			margin-bottom: 0;
		}
		.col-md-6 {
			&:first-child {
				padding-right: 40px;
				@include media-breakpoint-down(md) {
					padding-right: 15px;
				}

				@include media-breakpoint-down(sm) {
					margin-bottom: 30px;
				}
			}
			&:last-child {
				padding-left: 40px;
				@include media-breakpoint-down(md) {
					padding-left: 15px;
				}
			}
		}
	}
	img {
		max-width: 100%;
    	height: auto !important;
	}
}
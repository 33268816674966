// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

// Reassign color vars to semantic color scheme
$brand-primary:             #53abd9 !default;
$brand-secondary:           #263c53 !default;
$brand-blue: 				#273a7e !default;


// Body
//
// Settings for the `<body>` element.

$body-bg:       #fff !default;
$body-color:    #9fabb8 !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Ubuntu', sans-serif;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$line-height-base: 2 !default;

$font-size-h1: 2.25rem !default;
$font-size-h2: 1.5rem !default;
$font-size-h3: 1.4rem !default;
$font-size-h4: 1.25rem !default;
$font-size-h5: 1.15rem !default;
$font-size-h6: 1rem !default;

$headings-font-weight:   300 !default;
$headings-line-height:   1.5 !default;
$headings-color:         $brand-secondary !default;
// -------------------------------------------------------------
//   Navigation
// -------------------------------------------------------------
#nav {
    > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0;
        text-align: center;
        @include clearfix;

        @include media-breakpoint-down(lg) {
            padding: 0 0 30px;
        }
        > li {
            display: inline-block;
            position: relative;

            @include media-breakpoint-down(lg) {
                display: block;
            }
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: transparentize(#fff,0.85);
            }            
            > a, > .nolink {
                display: block;
                position: relative;
                padding: 48px 0;
                color: #fff;
                font-size: 0.95rem;
                font-weight: 500;
                line-height: 1.2em;
                text-decoration: none;
                @include transition(padding .2s);

                @include media-breakpoint-down(lg) {
                    padding: 21px 15px !important;
                }
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: $brand-primary;
                    @include transition(all .2s);

                    @include media-breakpoint-down(lg) {
                        background-color: #fff;
                    }
                }
            }
            & + li {
                padding-left: 40px;
                > ul {
                    left: 40px;
                    min-width: calc(100% - 40px);
                }

                @include media-breakpoint-down(lg) {
                    padding-left: 0;
                    > ul {
                        left: 0;
                        min-width: 100%;
                    }
                }
            }

            &:hover, &.active, &.active-trail {
                > a, > .nolink {
                    &:after {
                        width: 100%;
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                &:hover {
                    > ul {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                &:nth-child(3) {
                    &:after {
                        width: calc(100% - 45px);
                    }
                    &:hover, &.active, &.active-trail {
                        > a, > .nolink {
                            &:after {
                                width: calc(100% - 45px);
                            }
                        }
                    }
                }
                &:nth-child(4) {
                    margin-left: 174px;
                    padding-left: 0;
                    &:after {
                        left: 50px;
                        width: calc(100% - 50px);
                    }
                    > a, > .nolink {
                        &:after {
                            left: 50px;
                        }
                    }
                    > ul {
                        left: 50px;
                        min-width: calc(100% - 50px);
                    }
                    &:hover, &.active, &.active-trail {
                        > a, > .nolink {
                            &:after {
                                width: calc(100% - 50px);
                            }
                        }
                    }
                }
            }

            // Niv 2
            > ul {
                position: absolute;
                z-index: 1000;
                top: 100%;
                left: 0;
                margin: 0;
                padding: 0;
                width: 210px;
                min-width: 100%;
                background-color: $brand-primary;
                list-style: none;
                text-align: left;
                opacity: 0;
                visibility: hidden;
                @include transition(all .35s);

                @include media-breakpoint-down(lg) {
                    display: none;
                    position: relative;
                    top: auto;
                    left: auto;
                    width: 100%;
                    background-color: transparentize(#fff,0.8);
                    opacity: 1;
                    visibility: visible;
                    text-align: center;
                }
                > li {
                    > a, > .nolink {
                        display: block;
                        padding: 10px 15px;
                        color: #fff;
                        font-size: 0.9rem;
                        line-height: 1.2em;
                        text-decoration: none;
                        @include transition(all .2s);

                        @include media-breakpoint-down(lg) {
                            padding: 20px 15px;
                        }
                    }
                    & + li {
                        border-top: 1px solid transparentize(#fff,0.85);
                    }
                    &:hover, &.active {
                        > a, > .nolink {
                            background-color: darken($brand-primary, 15%);
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        > .sublvl {
            > li {
                &.expanded {
                    position: relative;
                    overflow: hidden;
                }
                .expand {
                    display: block;
                    position: absolute;
                    z-index: 5;
                    top: 0;
                    right: 0;
                    width: 60px;
                    height: 60px;
                    &:before {
                        content: '+';
                        display: block;
                        position: absolute;
                        top: 50%;
                        margin-top: -12px;
                        width: 100%;
                        color: #fff;
                        font-size: 24px;
                        line-height: 1em;
                        text-align: center;
                    }
                }
                &.open {
                    .expand {
                        &:before {
                            content: '-';
                        }
                    }
                    > ul {
                        display: block;
                    }
                }
            }
        }
    }
}

.main-header.scrolled {
    #nav {
        > ul {
            > li {       
                > a, > .nolink {
                    padding-top: 38px;
                    padding-bottom: 38px;
                    &:after {
                        background-color: #fff;
                    }
                }

                @include media-breakpoint-up(xl) {
                    &:nth-child(3) {
                        &:after {
                            width: 100%;
                        }
                        &:hover, &.active, &.active-trail {
                            > a, > .nolink {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    &:nth-child(4) {
                        &:after {
                            left: 0;
                            width: 100%;
                        }
                        > a, > .nolink {
                            &:after {
                                left: 0;
                            }
                        }
                        > ul {
                            left: 0;
                            min-width: 100%;
                        }
                        &:hover, &.active, &.active-trail {
                            > a, > .nolink {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.trigger-menu {
    position: relative;
    z-index: 5;
    float: left;
    margin-top: 20px;
    svg {
    	display: block;
        fill: $brand-primary;
    	rect {
    		-webkit-transform-origin: left center;
    		-moz-transform-origin: left center;
    		-ms-transform-origin: left center;
    		-o-transform-origin: left center;
    		transform-origin: left center;
    		-webkit-transform: rotate(0);
    		-moz-transform: rotate(0);
    		-ms-transform: rotate(0);
    		-o-transform: rotate(0);
    		transform: rotate(0);
    		@include transition(all .2s);
    	}
    }
    &.open {
    	svg {
            fill: #fff;
    		rect {
    			&:nth-child(1) {
    				-webkit-transform: rotate(45deg);
    				-moz-transform: rotate(45deg);
    				-ms-transform: rotate(45deg);
    				-o-transform: rotate(45deg);
    				transform: rotate(45deg);
    				x: 3px;
    			}
    			&:nth-child(2) {
    				width: 0%;
    				opacity: 0;
    			}
    			&:nth-child(3) {
    				-webkit-transform: rotate-(45deg);
    				-moz-transform: rotate(-45deg);
    				-ms-transform: rotate(-45deg);
    				-o-transform: rotate(-45deg);
    				transform: rotate(-45deg);
    				x: 3px;
    			}
    		}
    	}
    }
}

@include media-breakpoint-down(lg) {
    nav#nav {
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 60px 0;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        background-color: transparentize($brand-primary,0.02);
        overflow-x: auto;
        overflow-y: scroll;
        @include transition(all .35s);
    }
}

body.open-menu {
    @include media-breakpoint-down(lg) {
        height: 100%;
        overflow: hidden;
        nav#nav {
            visibility: visible;
            opacity: 1;
        }
    }
}
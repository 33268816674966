// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

body {
	padding-top: 60px;
}

.container.md {
	max-width: 990px;
}

.not-front {
	#main {
		margin-top: -90px;
		@include media-breakpoint-down(xs) {
			background-color: #fff;
		}
		.main-wrapper {
			padding-top: 50px;
			padding-bottom: 60px;
			background-color: #fff;
			> * {
				margin-left: auto;
				margin-right: auto;
				max-width: 960px;
			}
		}
	}
	
}

.page-search404, .page-search {
	#main {
		.main-wrapper {
			padding-left: 15px;
			padding-right: 15px;
			form {
				margin-bottom: 30px;
				.form-submit {
					margin-top: 30px;
				}
			}
		}
	}
}
// Breadcrumb
// =============================================================================

.breadcrumb {
	margin: 0;
	padding: 0;
	font-size: 0;
	list-style: none;
	text-align: center;
	li {
		display: inline-block;
		color: #fff;
		font-size: 0.9rem;
		font-weight: 400;
		line-height: normal;
		& + li {
			&:before {
				content: '/';
				padding: 0 5px;
			}
		}
	}
	a {
		color: #fff;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
// Import bootstrap librairy
@import
  'base/bootstrap';

// Import FontAwesome library
@import
  '../../node_modules/font-awesome/scss/font-awesome';

// Import Owl-carousel library
@import
  '../../node_modules/owl.carousel/src/scss/owl.carousel',
  '../../node_modules/owl.carousel/src/scss/owl.theme.default';

// Import Swiper library
@import
  'https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.2/css/swiper.min.css';

// 1. Configuration and base stuff
@import
  'base/fonts',
  'base/drupal';

// 2. Helpers
@import
  'helpers/easing',
  'helpers/animations';

// 3. Layouts
@import
  'layouts/header',
  'layouts/header/navigation',
  'layouts/header/banner',
  'layouts/footer',
  'layouts/main',
  'layouts/page/type',
  'layouts/page/paragraphs-cta',
  'layouts/page/paragraphs-galerie',
  'layouts/page/paragraphs-texte',
  'layouts/page/paragraphs-evidence';

// 4. Components
@import
  // 'components/hero',
  'components/longdash',
  'components/buttons',
  'components/breadcrumb',
  'components/forms',
  'components/bg',
  // 'components/og-grid',
  'components/dont-break-out';

// 5. Page-specific styles
@import
  'pages/homepage',
  'pages/webform',
  'pages/user';

// -------------------------------------------------------------
//   Logndash
//   Text style with left dash
// -------------------------------------------------------------
.longdash {
  position: relative;
  margin-left: 40px;
  //color: rgba( color(content-link), .7 );

  &:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 1px;
    left: -40px;
    top: 50%;
    margin-top: -1px;
    z-index: 1;
    background: color(content-link);
  }
}

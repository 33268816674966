// -------------------------------------------------------------
//   Paragraphs item cta
// -------------------------------------------------------------

.content-cta {
	margin: 30px 0;
	padding: 55px 60px;
	background-color: $brand-primary;
	font-size: 0;

	@include media-breakpoint-down(lg) {
		padding-left: 30px;
		padding-right: 30px;
	}

	@include media-breakpoint-down(sm) {
		padding-left: 15px;
		padding-right: 15px;
	}
	.content, .cta {
		display: inline-block;
		vertical-align: middle;
	}
	.content {
		padding-right: 15px;
		width: 66.66667%;
		@include media-breakpoint-down(md) {
			width: 58.33333%;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
			padding-right: 0;
			width: 100%;
			text-align: center;
		}
	}
	.cta {
		padding-left: 15px;
		width: 33.33333%;
		text-align: right;
		@include media-breakpoint-down(md) {
			width: 41.66667%;
		}
		@include media-breakpoint-down(sm) {
			padding-left: 0;
			width: 100%;
			text-align: center;
		}
	}
	h2 {
		margin: 0;
		color: #fff;
	}
	.btn {
		font-size: 1.15rem;
		line-height: 1em;
		.fa {
			margin-right: 10px;
			font-weight: 400;
		}
	}
}